<template>
    <div>
        <ValidationObserver v-slot="{handleSubmit, invalid}">
            <div class="form-group">
                <label
                    v-if="fromLink"
                    class="card__info-label required mt-3">
                    Email
                </label>
                <ValidationProvider
                    v-slot="{ errors }"
                    rules="required|email|external-email"
                    name="email">
                    <input
                        v-model="email"
                        :class="{
                            'loginV2__input': !fromLink,
                            'is-invalid': errors.length && fromLink
                        }"
                        class="form-control"
                        placeholder="Email"
                        name="email"
                        type="text">

                    <MessageValidationError
                        v-if="errors.length"
                        :msg="errors[0]">
                    </MessageValidationError>
                </ValidationProvider>
            </div>

            <div class="mt-4">
                <IconButton
                    :class="{
                        'btn-block py-3 my-3': !fromLink,
                        'py-2' : fromLink
                    }"
                    class="btn-primary forgot-password__reset-password-btn px-3"
                    icon="fa-envelope"
                    label="Send Reset Link"
                    :loading="isSubmitting"
                    :disabled="invalid"
                    @click="handleSubmit(sendResetEmail)"></IconButton>

                <router-link
                    :class="{
                        'loginV2__reset-password-link': !fromLink,
                        'btn btn-secondary-transparent ml-2 py-2': fromLink
                    }"
                    :to="{name: 'loginV2'}">
                    <div class="text-center">
                        Cancel
                    </div>
                </router-link>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
import api from '@api/accounts'
import {
    IconButton,
    MessageValidationError,
} from '@technologyadvice/relay-frontend'
import { extend } from 'vee-validate'

export default {
    components: {
        MessageValidationError,
        IconButton,
    },
    props: {
        fromLink: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            email: '',
            loading: false,
            isSubmitting: false,
        }
    },
    created() {
        extend('external-email', {
            message: () => 'This is a TechnologyAdvice user email address. Please sign in with Google.',
            validate: value => !value.toLowerCase().includes('@technologyadvice'),
        });
    },
    methods: {
        sendResetEmail() {
            this.isSubmitting = true
            api.sendPasswordResetEmail(this.email)
                .then(() => {
                    this.email = ''
                    this.$_toasterSubtle('If we have this email in our system, a reset link has been sent!', 5000);
                    this.$router.push({ name: 'loginV2' })
                })
                .finally(() => {
                    this.isSubmitting = false
                })
        },
    },
}
</script>
