<template>
    <div>
        <ClientBaseView
            title="Integration Hub"
            sub-title="Manage integrations with external products"
            :is-loading="isLoading"
            :load-error="error">
            <template #content>
                <CardUpgrade
                    v-if="shouldShowUpgradeCard"
                    title="Effortless Integration, Limitless Potential: Upgrade Now!"
                    blurb="Unleash the full power of your workflow with our new integrations feature.
                        By upgrading you can direct data export capabilities, seamless tool connectivity,
                        and a productivity boost that aligns with your path to liberation from inefficiency.">
                </CardUpgrade>

                <BaseCard
                    :show-header="false">
                    <template #body>
                        <div class="container-fluid p-0">
                            <div class="row no-gutters">
                                <template
                                    v-for="(integration, index) in integrationCards">
                                    <component
                                        :is="integration"
                                        :key="integration"
                                        class="col-xl-4 order-2 order-xl-1">
                                    </component>
                                    <IntegrationFeedbackForm
                                        v-if="index === 0"
                                        :key="index"
                                        class="col-xl-8 order-1 order-xl-2 mb-4 mb-xl-0">
                                    </IntegrationFeedbackForm>
                                </template>
                            </div>
                        </div>
                    </template>
                </BaseCard>
            </template>
        </ClientBaseView>
    </div>
</template>
<script>
import { ClientBaseView, BaseCard, CardUpgrade } from '@technologyadvice/relay-frontend';
import IntegrationFeedbackForm from '@components/integrations/IntegrationFeedbackForm.vue';
import { mapGetters } from 'vuex'
import { useIntegrationsStore } from '@store/integrations/index';
import ZoomInfoCard from '@components/integrations/Cards/ZoomInfoCard.vue';
import { mapActions, mapState } from 'pinia'

export default {
    name: 'IntegrationHub',
    components: {
        ClientBaseView,
        BaseCard,
        CardUpgrade,
        ZoomInfoCard,
        IntegrationFeedbackForm,
    },
    data() {
        return {
            showCard: false,
            openModal: false,
            isLoading: true,
            integrationCards: [ 'ZoomInfoCard' ],
        }
    },
    async created() {
        await this.load(this.user.account_uuid)
        this.isLoading = false
    },
    computed: {
        ...mapGetters('auth', [
            'user',
        ]),
        ...mapState(useIntegrationsStore, [ 'error' ]),
        shouldShowUpgradeCard() {
            return !this.user?.has_active_plan
                || (!this.user?.gates?.integrations || this.user?.gates?.integrations === 'false')
        },
    },
    methods: {
        ...mapActions(useIntegrationsStore, [ 'load' ]),
    },
}
</script>
