<template>
    <div>
        <div class="d-flex justify-content-center">
            <img
                alt="Traction Home"
                class="welcome-image my-4"
                :src="refreshHomeImage">
        </div>
        <div class="text-center">
            <h1>Welcome to Traction!</h1>
            <div>
                To get started, select a portal from the navigation list.
            </div>
        </div>
    </div>
</template>

<script>
import refreshHomeImage from '@technologyadvice/relay-frontend/src/assets/branding/design-refresh-home.png'

export default {
    data() {
        return {
            refreshHomeImage,
        }
    },
};
</script>
