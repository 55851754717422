<template>
    <BaseView
        title="Notification Preferences"
        sub-title="Manage when Traction requires your attention">
        <template #content>
            <BaseCard class="notifications">
                <template #title>
                    <Icon
                        icon="fa-bell"
                        class="mr-2 text-primary"></Icon>
                    My Notifications
                </template>
                <template #body>
                    <p class="bg-light w-100 py-4 px-4 rounded">
                        Use the toggles to indicate that you would like to receive notifications
                        from Traction for the related topic
                    </p>

                    <NotificationsIndex
                        @updated="handleSave"
                        @error="handleError">
                    </NotificationsIndex>
                </template>
            </BaseCard>
        </template>
    </BaseView>
</template>

<script>
import { BaseView, BaseCard, Icon } from '@technologyadvice/relay-frontend'
import { NotificationsIndex } from '@technologyadvice/notifications'

export default {
    components: {
        BaseView,
        BaseCard,
        Icon,
        NotificationsIndex,
    },
    methods: {
        handleError(msg) {
            this.$_toasterDanger(msg)
        },
        handleSave(msg) {
            this.$_toasterSuccess(msg)
        },
    },
}
</script>
